import React from 'react'


class PageNotFound extends React.Component{
    render(){
        return(
            <div>
              <h1>404</h1>    
              <h2>Такой страницы не существует</h2>
                <hr/>
            </div>
            
        )
    }
}

export default PageNotFound