import nalivaiko from './img/Nalivayko_otziv.jpeg'
import agroexport from './img/Agroexport_otziv.jpg'
import protectfeed from './img/ProtectFeed_otziv.jpg'
import agrogalan from './img/agrogalan_otziv.jpg'
import isaeva from './img/Isaeva_otzyv.jpg'
import shevchenko from './img/shevchenko_otzyv.jpg'

//if block has not img — isImg:false & img:''




// eslint-disable-next-line
export default [
    {
        isImg:true,
        img: nalivaiko,
        title: 'Абсолютная надежность',
        text: 'Практически за три года работы с ЦКК наша компания убедилась в абсолютной надежности и профессионализме испытательной лаборатории. Наше сотрудничество будет продолжаться и дальше.',
        signature: 'Галина Ивановна Наливайко,',
        position: 'Краснодарское представительство АО «Щелково АгроХим»,',
        location: 'г. Краснодар'
    },
    {
        isImg:true,
        img: agrogalan,
        title: 'Очень довольны нашим сотрудничеством',
        text: '«Вот уже на протяжении трех лет мы работаем с Центром контроля качества(ЦКК) и очень довольны нашим сотрудничеством. Мы направляем на лабораторные исследования, приобретенные у наших поставщиков ГСМ гербициды и пестициды. Всегда результаты получаем на следующий сутки и с абсолютной точностью. И в этом мы не раз убеждались. Кстати, мы находимся в Курганинском районе Краснодарского края, лаборатория (ЦКК) же расположена рядом с Усть-Лабинском, это не близкое расстояние, и тем не менее, мы везем на анализ наши ГСМ и СЗР только туда.»',
        signature: 'Евгения Семеновна Панюкова,',
        position: 'коммерческий директор ООО «Агро-Галан»,',
        location: 'ст. Константиновская, Курганинский район'
    },
    {
        isImg:true,
        img: agroexport,
        title: 'С ЦКК РАБОТАТЬ КОМФОРТНО',
        text: 'С периодичностью два раза в месяц нам необходимо проводить анализ дизельного топлива. Эти услуги с абсолютной безупречностью нам оказывает лаборатория Некрасовского «Центра контроля качества». Приезжает специалист ЦКК, забирает пробы, а на следующий день у нас документы с результатом. Очень удобно. За два года сотрудничества ни разу не было ни одного сбоя. Мы за это время убедились, как комфортно работать с ЦКК.',
        signature: 'Евгений Малахов,',
        position: 'AО «Ресурс Агроэкспорт»,',
        location: 'г. Усть-Лабинск'
    },
    {
        isImg:true,
        img: protectfeed,
        title: 'Оперативно и точно',
        text: 'Уже более двух лет сдаем на исследования в Центр контроля качества «Прогресс Агро» входное сырье, например, жмых соевый или подсолнечника, дрожжи кормовые, зерно сои, а также свою продукцию комбикорма и концентраты. У нас есть собственная лаборатория, но считаем необходимым регулярно проводить контроль для сравнения в сторонних, независимых и при этом аккредитованных лабораториях. Для нас - это некрасовский Центр контроля качества, располагающий хорошим оборудованием и всеми компетенциями. В компании мы считаем важным контролировать все малейшие отклонения и погрешности в производстве комбикормов, поэтому мы помимо собственной лаборатории и обращаемся в ЦКК. Порой привозим на анализ до 30 проб и уверены в точности проведённых исследований.',
        signature: 'Татьяна Аврова,',
        position: 'начальник производственной лаборатории ООО «Протекфид»,',
        location: 'ст. Новотитаровская, Динской район'
    },
    // {
    //     isImg:true,
    //     img: protectfeed,
    //     title: 'Оперативно и точно',
    //     text: 'Мы направляем на исследование образцы сырья для комбикормов, а это шрот и жмых, в Усть-Лабинский район в Некрасовский Центр контроля качества(ЦКК) по нескольким причинам. Во-первых, образцы принимают быстро, при этом приёмщицы очень вежливы. Во-вторых, анализ проводят оперативно и точно, уже на следующий день по электронной почте нам направляют результаты. Ну и наконец, в-третьих, наличие современного высокоточного оборудования в ЦКК действительно гарантирует нам точность исследования, и соответственно,  качество наших кормов.',
    //     signature: 'Евгений Станиславович Терещенко,',
    //     position: 'технолог ООО «Протекфид»,',
    //     location: 'ст. Новотитаровская, Динской район'
    // },
    {
        isImg:true,
        img: isaeva,
        title: 'Ответственная работа',
        text: 'Спасибо персоналу лаборатории за ответственную работу. В процессе сотрудничества, я отметила и доступность руководства и открытость персонала и конечно профессиональный подход к работе. У них нет штампов «так надо». Все наши не стандартные вопросы слышали, комментировали и с нами совместно находили пути решения, оптимальные и результативные. Все познается в сравнении и тут нужно отметить, что они на сегодня лучшие по моему мнению. В предоставлении спектра услуг, в предоставлении информационной поддержки, в документообороте, в гибкости по оплате и конечно в результатах исследований. Спасибо за вашу работу.',
        signature: 'Елена Анатольевна Исаева,',
        position: 'Менеджер по закупкам молока АО «Данон Россия»,',
        location: 'г. Москва'
    },
    {
        isImg:true,
        img: shevchenko,
        title: 'Виден конкретный результат',
        text: 'С Центром контроля качества «Прогресс Агро» работаем почти три года, привозим на анализ наши корма. Сотрудничеством довольна, так как вижу конкретный результат – рост продуктивности нашего стада коров. На основе полученного анализа я рассчитываю рацион всего стада – это важная и кропотливая работа, где точность исследования кормов определяет во многом прирост надоев. Мы являемся соседями по районам, наши фермы расположены в Тбилисском, а Центр в Усть-Лабинском. Но всегда находим возможность и время отвезти наши образцы кормов в станицу Некрасовскую, там, где находится лаборатории Центра, на анализ. Надеюсь и в дальнейшем на хорошее, плодотворное сотрудничество.',
        signature: 'Татьяна Мятликова,',
        position: 'главный зоотехник ЗАО ИМ Т.Г. Шевченко',
        location: 'хутор Шевченко, Краснодарский край'
    }

]
