// import coverImg1 from './sert1.png'
import coverImg1 from './sert1_2.jpg'
import coverImg1_prev from './sert1_prev.jpg'

// import coverImg2 from './sert2.png'
// import coverImg3 from './sert3.png'


const certificates = [
    {lead:'Lorem ipsum dolor sit amet consectetur adipisicing elit. At earum deleniti nihil quia tenetur'},
    [
        {
            sertImg_prev:coverImg1_prev,
            sertImg:coverImg1,
            sertName:'Аттестат признания компетентности лаборатории'
        }
    ]
]



export default certificates